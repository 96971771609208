import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReport } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";
import moment from "moment";
import excel from "../../components/excel/excel";
import "./reporting.css";
import Select from "react-select";
import createReport from "../../components/pdf/report";

export default function Report() {
    const dispatch = useDispatch();
    const salesReport = useSelector((state) => state.order.order);
    const currentUser = useSelector((state) => state.user.currentUser);
    const isFetching = useSelector((state) => state.order.isFetching);

    const TwoDecimalFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [isLoaded, setIsLoaded] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [totalUnit, setTotalUnit] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [depositAmount, setDepositAmount] = useState(0);
    const [SGtotalUnit, setSGTotalUnit] = useState(0);
    const [SGtotalAmount, setSGTotalAmount] = useState(0);
    const [SGdepositAmount, setSGDepositAmount] = useState(0);
    const [inputs, setInputs] = useState({
        createdBy: currentUser._id,
        principle: undefined,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        outlet: undefined,
    });

    const getAdmin = async () => {
        const res =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? await publicRequest.get("/users/admin", {
                      params: { isAdmin: true },
                  })
                : await publicRequest.get("/users/superadmin", {
                      params: { isAdmin: true },
                  });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const handleChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, principle: e.value, outlet: e.label };
        });
    };

    const handleDateFrom = (e) => {
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };
    const handleDateTo = (e) => {
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getReport(dispatch, inputs);
            setTotalAmount(0);
            setTotalUnit(0);
            setDepositAmount(0);
            setSGTotalAmount(0);
            setSGTotalUnit(0);
            setSGDepositAmount(0);
            setModalData(res);
            if (res) {
                setIsLoaded(true);
                for (const item of res) {
                    if (item.outlet === "HAOFA Tourbillon Singapore") {
                        setSGTotalUnit((prev) => prev + item.salesAmount);
                        setSGTotalAmount((prev) => prev + item.monthlySales);
                        setSGDepositAmount((prev) => prev + item.monthlyDeposit);
                    } else if (item.outlet !== "HAOFA Tourbillon Singapore"){
                        setTotalUnit((prev) => prev + item.salesAmount);
                        setTotalAmount((prev) => prev + item.monthlySales);
                        setDepositAmount((prev) => prev + item.monthlyDeposit);
                    }
                }
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getAdmin();
        }
    }, [usersData]);

    const columns = [
        {
            field: "outlet",
            headerName: "Outlet",
            width: 300,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "totalSales",
            headerName: "Total Sales",
            width: 300,
            sortable: false,
            renderCell: (params) => (
                <div className="initialPadding">
                    {" "}
                    {params
                        ? `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(
                              params.row.totalSales
                          )}`
                        : `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(0)}`}
                </div>
            ),
        },
        {
            field: "salesAmount",
            headerName: "Sales Units",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : 0}</div>
            ),
        },
        {
            field: "monthlySales",
            headerName: "Monthly Sales",
            width: 300,
            sortable: false,
            renderCell: (params) => (
                <div className="initialPadding">
                    {" "}
                    {params
                        ? `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(
                              params.row.monthlySales
                          )}`
                        : `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(0)}`}
                </div>
            ),
        },
        {
            field: "depositAmount",
            headerName: "Deposit Units",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : 0}</div>
            ),
        },
        {
            field: "monthlyDeposit",
            headerName: "Monthly Deposit",
            width: 300,
            sortable: false,
            renderCell: (params) => (
                <div className="initialPadding">
                    {" "}
                    {params
                        ? `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(
                              params.row.monthlyDeposit
                          )}`
                        : `${
                              process.env.REACT_APP_COUNTRY === "SINGAPORE" ||
                              params.row.outlet === "HAOFA Tourbillon Singapore"
                                  ? "$"
                                  : "RM"
                          } ${TwoDecimalFormatter.format(0)}`}
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="productList">
                <div className="samerow">
                <div className="addProductItem mr-4">
                    <Select
                                    options={usersData.map((user, i) => {
                                        return {
                                            label: `${user.name}`,
                                            value: user._id,
                                        };
                                    })}
                                    onChange={handleChange}
                                    placeholder={"Outlet"}
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? process.env.REACT_APP_COUNTRY ===
                                              "SINGAPORE"
                                                ? getAdmin()
                                                : getAllAdmins()
                                            : {}
                                    }
                                />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateFrom"
                            value={inputs.dateFrom}
                            type="date"
                            onChange={(e) => handleDateFrom(e)}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateTo"
                            value={inputs.dateTo}
                            type="date"
                            onChange={(e) => handleDateTo(e)}
                        />
                    </div>
                </div>
                <div className="block">
                    <button
                        onClick={() => {
                            excel({
                                excelData: salesReport,
                                fileName: `Haofa-Sales-${moment(
                                    inputs.dateFrom
                                ).format("DD-MM-YYYY")}-to-${moment(
                                    inputs.dateTo
                                ).format("DD-MM-YYYY")}`,
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                </div>

                <div className="block">
                    <button
                        onClick={() => {
                            const reportData = {
                                ...salesReport[0],
                                dateTo: inputs.dateTo,
                                dateFrom: inputs.dateFrom,
                                outlet: inputs.outlet ? inputs.outlet : "All",
                              };
                            createReport(reportData)
                        }}
                        className="green buttonTop productListButton"
                    >
                        Generate Report
                    </button>
                </div>
                {isLoaded === true && (
                    <DataGrid
                        rows={isFetching === false ? modalData : []}
                        disableSelectionOnClick
                        columns={columns}
                        pageSize={100}
                        disableColumnMenu
                    />
                )}
                <div className="reportContainer">
                    <div className="featuredReport blue">
                        <span className="featuredTitle">Total Units:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney"> {totalUnit}</span>
                        </div>
                    </div>
                    <div className="featuredReport blue">
                        <span className="featuredTitle">Total Amount:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}{" "}
                                {TwoDecimalFormatter.format(totalAmount)}
                            </span>
                        </div>
                    </div>
                    <div className="featuredReport blue">
                        <span className="featuredTitle">Total Deposit:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                    ? "$"
                                    : "RM"}{" "}
                                {TwoDecimalFormatter.format(depositAmount)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="reportContainer">
                    <div className="featuredReport red">
                        <span className="featuredTitle">SG Total Units:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney"> {SGtotalUnit}</span>
                        </div>
                    </div>
                    <div className="featuredReport red">
                        <span className="featuredTitle">SG Total Amount:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                           $
                                {TwoDecimalFormatter.format(SGtotalAmount)}
                            </span>
                        </div>
                    </div>
                    <div className="featuredReport red">
                        <span className="featuredTitle">SG Total Deposit:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                $
                                {TwoDecimalFormatter.format(SGdepositAmount)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
