import "./warrantyList.css";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getStockCheck,
} from "../../redux/apiCalls";
import excel from "../../components/excel/excel";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import "./warrantyList.css";
import Table from "../../components/advancedTable";
import outletList from "../../outletList";

const statusOptions = [
    {
        label: "Stock",
        value: "inactive",
    },
    {
        label: "Sold",
        value: "active",
    },
    {
        label: "Banned",
        value: "banned",
    },
    {
        label: "All",
        value: "",
    },
];


export default function StockList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const adminOutlet = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const stockKeeper = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.isStockKeeper : null
);

        const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const specialAccess = useSelector((state) =>
        state.user.currentUser
            ? ["64be53a291c85522064a711a", "668272b7411b6b5ff615c3be", "6426333a94c2157f1ba818b9", "66a34e8ece77d0f508a034cb","67207842c631f38e3745b1f6"].includes(
                  state.user.currentUser._id
              )
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [isLoading, setIsLoading] = useState(false);
    const warranties = useSelector((state) => state.warranty.warranties);
    const [isLoaded, setIsLoaded] = useState(false);
    const [warrantyData, setWarrantyData] = useState([]);
    const [serialNumber, setSerialNumber] = useState("");
    const [outlet, setOutlet] = useState("");
    const [status, setStatus] = useState("");
    const [model, setModel] = useState("");
    const [inputs, setInputs] = useState({
        model: "",
        userId: 
            specialAccess
            ? "63429be7a4b0715d61b8955c"
            : manager
            ? principle
            : staff
            ? principle
            : superadmin 
            ? "63429be7a4b0715d61b8955c"
            : stockKeeper
            ? "63429be7a4b0715d61b8955c"
            : marketing
            ? adminOutlet
            : undefined,
        limit: 100,
        page: 1,
        status: "",
        serialNumber: "",
        endOn: undefined,
        dateFrom: undefined,
        outlet: specialAccess
            ? ""
            : superadmin
            ? ""
            : stockKeeper
            ? ""
            : manager
            ? principle
            : marketing
            ? adminOutlet
            : adminOutlet,
    });
    const [usersData, setUsersData] = useState([]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isLoaded === false) {
                try {
                    setIsLoading(true)
                    const result = await getStockCheck(dispatch, inputs);
                    if (result) {
                        setIsLoading(false)
                        setWarrantyData(result.payload);
                        setIsLoaded(true);
                    }
  
                } catch (error) {
                    console.error("Error fetching warranties:", error);
                }
            }
        };

        if (warrantyData[0] === undefined) {
            fetchData();
        }
    }, [dispatch, inputs, warrantyData, isLoaded]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    const handleInputStatus = (e) => {
        if (e.value !== "") {
            setWarrantyData(
                warranties.payload.filter(
                    (warranty) =>
                        warranty.status === e.value &&
                        (model === "" ||
                            warranty.productColor.includes(model)) &&
                        (serialNumber === "" ||
                            warranty.serialNumber.includes(serialNumber)) &&
                        (outlet === "" || warranty.outlet.includes(outlet))
                )
            );
            setStatus(e.value);
        } else {
            setWarrantyData(
                warranties.payload.filter(
                    (warranty) =>
                        (model === "" ||
                            warranty.productColor.includes(model)) &&
                        (serialNumber === "" ||
                            warranty.serialNumber.includes(serialNumber)) &&
                        (outlet === "" || warranty.outlet.includes(outlet))
                )
            );
            setStatus(undefined);
        }
    };

    const handleModelChange = (e) => {
        setWarrantyData(
            warranties.payload.filter(
                (warranty) =>
                    warranty.productColor.includes(e.target.value) &&
                    (status === "" || warranty.status === status) &&
                    (serialNumber === "" ||
                        warranty.serialNumber.includes(serialNumber)) &&
                    (outlet === "" || warranty.outlet.includes(outlet))
            )
        );
        setModel(e.target.value);
    };

    const handleSerialChange = (e) => {
        setWarrantyData(
            warranties.payload.filter(
                (warranty) =>
                    warranty.serialNumber.includes(e.target.value) &&
                    (status === "" || warranty.status === status) &&
                    (model === "" || warranty.productColor.includes(model)) &&
                    (outlet === "" || warranty.outlet.includes(outlet))
            )
        );
        setSerialNumber(e.target.value);
    };

    const handleOutletChange = (e) => {
        setWarrantyData(
            warranties.payload.filter(
                (warranty) =>
                    warranty.outlet.includes(e.value) &&
                    (status === "" || warranty.status === status) &&
                    (model === "" || warranty.productColor.includes(model)) &&
                    (serialNumber === "" || warranty.serialNumber.includes(serialNumber))
            )
        );
        setOutlet(e.value);
    };
    
    const hqColumns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name ? value.name : "" : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => {
                    const selectedOutlet = outletList.find(
                        (outlet) => outlet.id === value
                    );
                    return (
                        <div className="capitalize">
                            {value ? selectedOutlet ? selectedOutlet.name : "" : ""}
                        </div>
                    );
                },
            },
            {
                accessor: "createdAt",
                Header: "Start Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Warranty Until",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).add(3, "y").format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name ? value.name : "" : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => {
                    const selectedOutlet = outletList.find(
                        (outlet) => outlet.id === value
                    );
                    return (
                        <div className="capitalize">
                          {value ? selectedOutlet ? selectedOutlet.name : "" : ""}
                        </div>
                    );
                },
            },
            {
                accessor: "createdAt",
                Header: "Start Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Warranty Until",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).add(3, "y").format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
        ],
        []
    );

    return (
        <>
        {isLoading ? (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        ) : (
        <div className="productList ">
            <div className="samerow">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="serialNumber"
                        value={serialNumber}
                        type="string"
                        placeholder="Serial Number"
                        onChange={handleSerialChange}
                    />
                </div>
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="model"
                        value={model}
                        type="string"
                        placeholder="Model (Select Export Format)"
                        onChange={handleModelChange}
                    />
                </div>

                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleInputStatus}
                        placeholder="Status"
                    />
                </div>

                <div className="sameRowItem mb-2 marginRight">
                    {(superadmin || specialAccess) && (
                        <>
                            <Select
                                options={usersData.map((user, i) => {
                                    return {
                                        label: `${user.name}`,
                                        value: user._id,
                                    };
                                })}
                                onChange={handleOutletChange}
                                placeholder={"Outlet"}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="block mobileGrid">
                {superadmin && (
                    <>
                    <button
                        onClick={() => {
                            let payload = [];
                            warrantyData.forEach((warranty) => {
                                const selectedOutlet = outletList.find(
                                    (outlet) => outlet.id === warranty.outlet
                                );

                                payload.push({
                                    outlet: selectedOutlet ? selectedOutlet.name : "",
                                    model: warranty.productColor,
                                    serialNumber: warranty.serialNumber,
                                    type: warranty.type,
                                    startDate: warranty.startDate,
                                });
                            });
                            excel({
                                excelData: payload,
                                fileName: "HaofaStocks",
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                     <button
                     className="buttonTop productListButton"
                     onClick={async () => {
                        setWarrantyData(
                            warranties.payload
                        );
                        setModel("");
                        setSerialNumber("");
                        setOutlet("");
                        setStatus("");
                     }}
                 >
                     Reset
                 </button>
                 </>
                )}
            </div>
            {superadmin && (
                <div className="reportContainer">
                    <div className="featuredReport red">
                        <span className="featuredTitle">Filtered Stock:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {" "}
                                {warrantyData.length}
                            </span>
                        </div>
                    </div>

                    <div className="featuredReport blue">
                        <span className="featuredTitle">All Stock:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {" "}
                                {warranties.totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {isLoaded === true && (
                <Table
                    data={warrantyData}
                    columns={superadmin ? hqColumns : columns}
                    pageCount={Math.floor(warranties.totalPages / 10) + 2}
                    totalCount={warranties.totalPages}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                    hidePagination={inputs.limit === 10 ? false : true}
                />
            )}
        </div>
        )}
        </>
    );
}
