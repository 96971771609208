import "./sidebar.css";
import {
    LineStyle,
    Timeline,
    PermIdentity,
    Storefront,
    AttachMoney,
    CardTravel,
    AccountBalance,
    Add,
    Report,
    StoreOutlined,
    ShoppingCart,
    Apartment,
    Assessment,
    Flag,
    QueryBuilder,
    Receipt,
    Spellcheck,
    AllInbox,
Code,   
Security,
AddAlert,
Send,
ReportOutlined,
Redeem,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar() {
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );
    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Dashboard</h3>
                    <ul className="sidebarList">
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                            className="link"
                        >
                            <li className="sidebarListItem active">
                                <LineStyle className="sidebarIcon" />
                                Home
                            </li>
                        </Link>
                        {!accountant && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/pos"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Timeline className="sidebarIcon" />
                                        POS
                                    </li>
                                </Link>
                            </>
                        )}
                        {(superadmin || manager || admin || accountant) && (
                            <>
                                {" "}
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/deposits"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Apartment className="sidebarIcon" />
                                        Deposit
                                    </li>
                                </Link>

                                {/* <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/returns"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Redeem className="sidebarIcon" />
                                        Returns
                                    </li>
                                </Link> */}

{(superadmin || marketing || accountant) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/completeorder"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <CardTravel className="sidebarIcon" />
                                        Ecommerce / Campaign
                                    </li>
                                </Link>
                            </>
                        )}
                                
                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Users</h3>
                    <ul className="sidebarList">
                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/users"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <PermIdentity className="sidebarIcon" />
                                        Users
                                    </li>
                                </Link>
                            </>
                        )}
                        {!accountant && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/newuser"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Add className="sidebarIcon" />
                                        New User
                                    </li>
                                </Link>
                            </>
                        )}
                        {superadmin && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/outlets"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <StoreOutlined className="sidebarIcon" />
                                        Outlets
                                    </li>
                                </Link>
                            </>
                        )}
                          <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/commissions"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Redeem className="sidebarIcon" />
                                        Commissions
                                    </li>
                                </Link>
                            </>
                    </ul>
                </div>

                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Warranty</h3>
                    <ul className="sidebarList">
                        {(superadmin || manager || admin) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/warranties"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Security className="sidebarIcon" />
                                        Warranties
                                    </li>
                                </Link>
                            </>
                        )}
                        {superadmin && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/newwarranty"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Add className="sidebarIcon" />
                                        New Warranty
                                    </li>
                                </Link>

                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Stock</h3>
                    <ul className="sidebarList">
                        {(superadmin || manager || admin) && (
                            <>
                               
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/stocks"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <AccountBalance className="sidebarIcon" />
                                        Stocks
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/deliveryorders"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Spellcheck className="sidebarIcon" />
                                        Delivery Orders
                                    </li>
                                </Link>
                            </>
                        )}
                        {superadmin && (
                            <>
                               

                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/transactions"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Report className="sidebarIcon" />
                                        Consignment / Outright
                                    </li>
                                </Link>

                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/transferhistory"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Report className="sidebarIcon" />
                                        Transfer History
                                    </li>
                                </Link>
                            </>
                        )}
                                                {(superadmin || admin) && (
                            <>

                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/transfer"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Send className="sidebarIcon" />
                                        Transfer
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Orders</h3>
                    <ul className="sidebarList">
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/orders"
                            className="link"
                        >
                            <li className="sidebarListItem">
                                <AttachMoney className="sidebarIcon" />
                                Orders
                            </li>
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/completedeposit"
                            className="link"
                        >
                            <li className="sidebarListItem">
                                <AllInbox className="sidebarIcon" />
                                Completed Deposit
                            </li>
                        </Link>
                        {(superadmin || marketing || accountant) && (
                            <>
                                {" "}
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/campaigns"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <AddAlert className="sidebarIcon" />
                                        Campaigns
                                    </li>
                                </Link>
                            </>
                        )}
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/history"
                            className="link"
                        >
                            <li className="sidebarListItem">
                                <ShoppingCart className="sidebarIcon" />
                                Customer History
                            </li>
                        </Link>
                    </ul>
                </div>

                <div className="sidebarMenu">
                {(superadmin || admin || marketing) && (
                    <h3 className="sidebarTitle">Products</h3>)}
                    <ul className="sidebarList">
                        {(superadmin || admin || marketing) && (
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/products"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Storefront className="sidebarIcon" />
                                    Products
                                </li>
                            </Link>
                        )}
                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/newproduct"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Add className="sidebarIcon" />
                                        New Product
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>

                <div className="sidebarMenu">      {(superadmin || marketing) && (
                    <h3 className="sidebarTitle">Gift Cards</h3>)}
                    <ul className="sidebarList">
                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/affiliates"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Code className="sidebarIcon" />
                                        Affiliates
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/newaffiliate"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Add className="sidebarIcon" />
                                        New Affiliate
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
                <div className="sidebarMenu">
                {(superadmin || marketing) && (
                    <h3 className="sidebarTitle">Reports</h3>)}
                    <ul className="sidebarList">
                        {superadmin && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/productreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Assessment className="sidebarIcon" />
                                        Report Analysis
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/dailyreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <QueryBuilder className="sidebarIcon" />
                                        Daily Report
                                    </li>
                                </Link>
                               
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/report"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <QueryBuilder className="sidebarIcon" />
                                        Monthly Report
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/modelreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <ReportOutlined className="sidebarIcon" />
                                        Model Report
                                    </li>
                                </Link>
                               
                            </>
                        )}

                        {(superadmin || marketing) && (
                            <>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/salesreport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Flag className="sidebarIcon" />
                                        Outlet Report
                                    </li>
                                </Link>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/affiliatereport"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Receipt className="sidebarIcon" />
                                        Affiliate Report
                                    </li>
                                </Link>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}
