import "./orderList.css";
import Table from "../../components/advancedTable";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
    RestorePage,
    PictureAsPdf
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOrders, voidOrder } from "../../redux/apiCalls";
import moment from "moment";
import createPdf from "../../components/pdf/createPdf";
import { publicRequest } from "../../requestMethods";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";
import excel from "../../components/excel/excel";
import { useLocation } from "react-router";

const statusOptions = [
    {
        label: "Deposit",
        value: "deposit",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Void",
        value: "void",
    },
    {
        label: "Warranty",
        value: "warranty",
    },
    {
        label: "All",
        value: "",
    },
];

const limitOptions = [
    {
        label: "Basic",
        value: 20,
    },
    {
        label: "Export",
        value: 9999,
    },
];

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const location = useLocation();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const admin = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.isAdmin : null
);

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [inputs, setInputs] = useState({
        createdBy: manager
            ? principle
            : staff
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: undefined,
        limit: 9999,
        page: 1,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        user: location
            ? location.state
                ? location.state.outlet
                : undefined
            : undefined,
    });

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const [statusMenu, setStatusMenu] = useState(false);
    const [ordersData, setOrdersData] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    const handleDelayedChange = (e) => {
        setSerialNumber(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getOrders(dispatch, inputs);
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
    }, [dispatch, inputs, isLoaded]);
    

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            if (process.env.REACT_APP_COUNTRY === "SINGAPORE") {
                getAdmins();
            } else {
                getAllAdmins();
            }
        }
    }, [usersData]);

    const handleVoid = (id) => {
        voidOrder(id, dispatch);
    };

    const handleChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const handleLimitChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, limit: e.value };
        });
    };

    const columns = [
        {
            accessor: "action",
            Header: "Action",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {(superadmin || accountant) && (
                            <CloudDownload
                                className="pointer icon"
                                onClick={() => {
                                    createPdf(params.row.original);
                                }}
                            />
                        )}
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon"
                                onClick={() => {
                                    setModalData(params.row.original._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        <RestorePage
                            className="pointer icon"
                            onClick={() => {
                                createCustomerInvoice(params.row.original);
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "createdAt",
            Header: "Purchase Date",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            accessor: "product",
            Header: "Products",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon"
                            onClick={() => {
                                setModalData(params.row.original.products);
                                setStatusMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "invoiceId",
            Header: "Invoice",
            sortable: false,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "receipt",
            Header: "Receipt",
            width: 200,
            sortable: false,
            Cell: ({ value }) => {
                if (value) {
                    return (
                        <div className="flex justify-center items-center productListItem cursor-pointer">
                            <div
                                onClick={() => {
                                    window.open(value, "_blank"); // Open image in a new tab if clicked
                                }}
                            >
                                <PictureAsPdf />
                            </div>
                        </div>
                    );
                } else {
                    return <div></div>;
                }
            },
        },
        {
            accessor: "y",
            Header: "Model",
            sortable: false,
            Cell: (params) => {
                return (
                    <div className="initialPadding">
{params && params.row && params.row.original && params.row.original.products && params.row.original.products[0] ? params.row.original.products[0].code : ""
}                    </div>
                );
            },
        },

        {
            accessor: "warranty",
            Header: "Warranty",
            sortable: false,
            Cell: (params) => {
                return (
                    <div className="initialPadding">
                        {params
                            ? params.row.original.products[0]
                                ? params.row.original.products[0].warranty
                                    ? params.row.original.products[0].warranty
                                          .serialNumber
                                    : ""
                                : ""
                            : ""}
                    </div>
                );
            },
        },
        {
            accessor: "userId",
            Header: "Customer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },

        {
            accessor: "amount",
            Header: "Retail",
            sortable: false,
            Cell: (params) => (
                <div className="capitalize">
                    {params
                        ? params.row.original.country === "singapore"
                            ? "$"
                            : "RM"
                        : "RM"}
                    {formatter.format(
                        params
                            ? params.row.original.amount
                                ? params.row.original.amount
                                : 0
                            : 0
                    )}
                </div>
            ),
        },
        {
            accessor: "discount",
            Header: "Discount",
            sortable: false,
            Cell: (params) => (
                <div className="capitalize">
                    {params
                        ? params.row.original.country === "singapore"
                            ? "$"
                            : "RM"
                        : "RM"}
                    {formatter.format(
                        params
                            ? params.row.original.discount
                                ? params.row.original.discount
                                : 0
                            : 0
                    )}
                </div>
            ),
        },
        {
            accessor: "products",
            Header: "Total Paid",
            sortable: false,
            Cell: (params) => {
                let total = 0;
                if (params.row.original.products !== undefined) {
                    for (const product of params.row.original.products) {
                        total = total + product.price;
                    }
                }
                return (
                    <div className="capitalize">
                        {params
                            ? params.row.original.country === "singapore"
                                ? "$"
                                : "RM"
                            : "RM"}
                        {formatter.format(
                            params.row.original.deposit &&
                                params.row.original.status === "deposit"
                                ? params.row.original.deposit
                                : params.row.original.deposit &&
                                  params.row.original.status !== "deposit"
                                ? total - params.row.original.discount
                                : total - params.row.original.discount
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "cashier",
            Header: "Cashier",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "createdBy",
            Header: "Outlet",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "affiliateCode",
            Header: "Promo Code",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "paymentType",
            Header: "Payment Type",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "salesType",
            Header: "Sales Type",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "status",
            Header: "Status",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
    ];

    return (
        <>
            {!isLoaded ? (
                <></>
            ) : (
                <>
                    <div className="productList">
                        <div className="samerow">
                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="serialNumber"
                                    value={serialNumber}
                                    type="string"
                                    placeholder="Warranty"
                                    onChange={(e) => handleDelayedChange(e)}
                                />
                            </div>

                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="dateFrom"
                                    value={inputs.dateFrom}
                                    type="date"
                                    onChange={(e) => handleDateFrom(e)}
                                />
                            </div>
                            <div className="addProductItem ">
                                <input
                                    className="inputFilter"
                                    name="dateTo"
                                    value={inputs.dateTo}
                                    type="date"
                                    onChange={(e) => handleDateTo(e)}
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                                <Select
                                    options={statusOptions.map((status, i) => {
                                        return {
                                            label: status.label,
                                            value: status.value,
                                        };
                                    })}
                                    onChange={handleInputStatus}
                                    placeholder="Status"
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? getAdmins()
                                            : {}
                                    }
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                            {admin && !staff && (
                                    <Select
                                        options={limitOptions.map(
                                            (status, i) => {
                                                return {
                                                    label: `${status.label}`,
                                                    value: status.value,
                                                };
                                            }
                                        )}
                                        onChange={handleLimitChange}
                                        placeholder={"Format"}
                                    />
                            )}
                            </div>
                        </div>

                        <div className="sameRowItem mb-4 marginRight">
                            {superadmin && (
                                <Select
                                    options={usersData.map((user, i) => {
                                        return {
                                            label: `${user.name}`,
                                            value: user._id,
                                        };
                                    })}
                                    onChange={handleChange}
                                    placeholder={"Outlet"}
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? process.env.REACT_APP_COUNTRY ===
                                              "SINGAPORE"
                                                ? getAdmins()
                                                : getAllAdmins()
                                            : {}
                                    }
                                />
                            )}
                        </div>

                        <div className="block">
                            {superadmin && (
                                <button
                                    onClick={() => {
                                        let payload = [];
                                        ordersData.payload.forEach(
                                            (order, i) => {
                                                payload.push({
                                                    No: i + 1,
                                                    Outlet: order.createdBy
                                                        .name,
                                                    SKU: order.products[0].code,
                                                    Warranty: order.products[0]
                                                        .warranty
                                                        ? order.products[0]
                                                              .warranty
                                                              .serialNumber
                                                        : "",
                                                    Retail: `${
                                                        process.env
                                                            .REACT_APP_COUNTRY ===
                                                        "SINGAPORE"
                                                            ? "$"
                                                            : "RM"
                                                    } ${order.amount}`,
                                                    Discount: `${
                                                        process.env
                                                            .REACT_APP_COUNTRY ===
                                                        "SINGAPORE"
                                                            ? "$"
                                                            : "RM"
                                                    } ${order.discount}`,
                                                    Sales: `${
                                                        process.env
                                                            .REACT_APP_COUNTRY ===
                                                        "SINGAPORE"
                                                            ? "$"
                                                            : "RM"
                                                    } ${
                                                        order.amount -
                                                        order.discount -
                                                        (order.deposit
                                                            ? order.deposit
                                                            : 0)
                                                    }`,
                                                    Deposit: `${
                                                        process.env
                                                            .REACT_APP_COUNTRY ===
                                                        "SINGAPORE"
                                                            ? "$"
                                                            : "RM"
                                                    } ${
                                                        order.deposit
                                                            ? order.deposit
                                                            : 0
                                                    }`,
                                                    User: order.userId ? order.userId.name : "-",
                                                    Cashier: order.cashier ? order.cashier.name : "-",
                                                    PurchaseDate: moment(
                                                        order.createdAt
                                                    ).format("YYYY-MM-DD"),
                                                    DepositDate: moment(
                                                        order.depositDate
                                                    ).format("YYYY-MM-DD"),
                                                });
                                            }
                                        );
                                        excel({
                                            excelData: payload,
                                            fileName: `Haofa-Orders-${moment(
                                                inputs.dateFrom
                                            ).format("DD-MM-YYYY")}-to-${moment(
                                                inputs.dateTo
                                            ).format("DD-MM-YYYY")}`,
                                        });
                                    }}
                                    className="red buttonTop productListButton"
                                >
                                    Export
                                </button>
                            )}
                        </div>
                        {isLoaded === true && (
                            <Table
                                data={ordersData.payload}
                                columns={columns}
                                pageCount={
                                    ordersData
                                        ? Math.floor(
                                              ordersData.totalPages / 20
                                          ) + 2
                                        : 1
                                }
                                totalCount={ordersData.totalPages}
                                updatePagination={setInputs}
                                paginationState={inputs}
                                updateLoaded={setIsLoaded}
                            />
                        )}
                        <div className="reportContainer">
                            <div className="featuredReport blue">
                                <span className="featuredTitle">
                                    Total Orders:
                                </span>
                                <div className="featuredReportContainer">
                                    <span className="featuredMoney">
                                        {" "}
                                        {ordersData.totalPages}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {statusMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setStatusMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="updatemodalContainer">
                                    <div className="updateOrder">
                                        <h1 className="updateProductTitle">
                                            Products({modalData.length})
                                        </h1>
                                        <div className="productContainer">
                                            {modalData.map((product) => {
                                                return (
                                                    <div
                                                        className="product"
                                                        key={product}
                                                    >
                                                        {" "}
                                                        <div className="justify-center flex">
                                                            <img
                                                                src={
                                                                    product.img
                                                                }
                                                                className="productImg"
                                                                alt="productImages"
                                                            />
                                                        </div>
                                                        <div className="productDetails">
                                                            {product.code}
                                                        </div>
                                                        <div className="productDetails">
                                                            {process.env
                                                                .REACT_APP_COUNTRY ===
                                                            "SINGAPORE"
                                                                ? "$"
                                                                : "RM"}
                                                            {product.price.toLocaleString(
                                                                "en-US"
                                                            )}
                                                            .00
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {voidMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setVoidMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Confirm Void Order?
                                        </h3>
                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleVoid(modalData);
                                                    setVoidMenu(false);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setVoidMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
