import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";

const createReport = async (original) => {
    try {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        const timesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
        

        // Add a blank page to the document
        const page = pdfDoc.addPage();
        const fontSize = 20;

        // Get the width and height of the page
        const { height } = page.getSize();

        const jpgUrl =
          "https://ik.imagekit.io/dnddecpho/Haofa/receipt__jfAFa9wP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671007440100"
        const jpgImageBytes = await fetch(jpgUrl).then((res) =>
            res.arrayBuffer()
        );
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        const jpgDims = jpgImage.scale(0.5);
        page.drawImage(jpgImage, {
            x: 0,
            y: height - 6 * fontSize,
            width: jpgDims.width,
            height: jpgDims.height,
        });

        page.drawText(`REPORT`, {
            x: 255,
            y: height - 7.8 * fontSize,
            size: 20,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Date From:`, {
            x: 60,
            y: height - 9.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(original.dateFrom).format("DD MMM YYYY")}`, {
            x: 180,
            y: height - 9.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Date To:`, {
            x: 60,
            y: height - 10.3 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(original.dateTo).format("DD MMM YYYY")}`, {
            x: 180,
            y: height - 10.3 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Name:`, {
            x: 60,
            y: height - 11 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.outlet}`, {
            x: 180,
            y: height - 11 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 11.55 * fontSize,
            },
            end: {
                x: 30,
                y: height - 18.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawLine({
            start: {
                x: 560,
                y: height - 11.55 * fontSize,
            },
            end: {
                x: 560,
                y: height - 18.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 11.55 * fontSize,
            },
            end: {
                x: 560,
                y: height - 11.55 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Sales Summary", {
            x: 60,
            y: height - 12.35 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText("Qty", {
            x: 350,
            y: height - 12.35 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText("Amount", {
            x: 450,
            y: height - 12.35 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 12.85 * fontSize,
            },
            end: {
                x: 560,
                y: height - 12.85 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Cash Sales", {
            x: 60,
            y: height - 13.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlySalesCashQuantity)}`, {
            x: 350,
            y: height - 13.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlySalesCashAmount)}`, {
            x: 450,
            y: height - 13.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Card Sales", {
            x: 60,
            y: height - 14.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlySalesCardQuantity)}`, {
            x: 350,
            y: height - 14.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlySalesCardAmount)}`, {
            x: 450,
            y: height - 14.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Bank Transfer Sales", {
            x: 60,
            y: height - 15.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlySalesTransferQuantity)}`, {
            x: 350,
            y: height - 15.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlySalesTransferAmount)}`, {
            x: 450,
            y: height - 15.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Other Sales", {
            x: 60,
            y: height - 16.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlySalesOthersQuantity)}`, {
            x: 350,
            y: height - 16.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlySalesOthersAmount)}`, {
            x: 450,
            y: height - 16.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 17.35 * fontSize,
            },
            end: {
                x: 560,
                y: height - 17.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Total Summary", {
            x: 60,
            y: height - 18 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlySalesOthersQuantity + original.monthlySalesCardQuantity + original.monthlySalesCashQuantity + original.monthlySalesTransferQuantity)}`, {
            x: 350,
            y: height - 18 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlySalesOthersAmount + original.monthlySalesCardAmount + original.monthlySalesCashAmount + original.monthlySalesTransferAmount)}`, {
            x: 450,
            y: height - 18 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 18.35 * fontSize,
            },
            end: {
                x: 560,
                y: height - 18.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 19.55 * fontSize,
            },
            end: {
                x: 560,
                y: height - 19.55 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 19.55 * fontSize,
            },
            end: {
                x: 30,
                y: height - 26.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawLine({
            start: {
                x: 560,
                y: height - 19.55 * fontSize,
            },
            end: {
                x: 560,
                y: height - 26.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Deposits Summary", {
            x: 60,
            y: height - 20.35 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText("Qty", {
            x: 350,
            y: height - 20.35 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText("Amount", {
            x: 450,
            y: height - 20.35 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 20.85 * fontSize,
            },
            end: {
                x: 560,
                y: height - 20.85 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Cash Deposits", {
            x: 60,
            y: height - 21.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlyDepositCashAmount)}`, {
            x: 350,
            y: height - 21.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlyDepositCashAmount)}`, {
            x: 450,
            y: height - 21.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Card Deposits", {
            x: 60,
            y: height - 22.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlyDepositCardQuantity)}`, {
            x: 350,
            y: height - 22.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlyDepositCardAmount)}`, {
            x: 450,
            y: height - 22.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Bank Transfer Deposits", {
            x: 60,
            y: height - 23.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlyDepositTransferQuantity)}`, {
            x: 350,
            y: height - 23.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlyDepositTransferAmount)}`, {
            x: 450,
            y: height - 23.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Other Deposits", {
            x: 60,
            y: height - 24.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlyDepositOthersQuantity)}`, {
            x: 350,
            y: height - 24.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlyDepositOthersAmount)}`, {
            x: 450,
            y: height - 24.85 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 25.35 * fontSize,
            },
            end: {
                x: 560,
                y: height - 25.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText("Total Deposits", {
            x: 60,
            y: height - 26 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${(original.monthlyDepositOthersQuantity + original.monthlyDepositCardQuantity + original.monthlyDepositCashQuantity + original.monthlyDepositTransferQuantity)}`, {
            x: 350,
            y: height - 26 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawText(`RM ${NumberFormatter.format(original.monthlyDepositOthersAmount + original.monthlyDepositCardAmount + original.monthlyDepositCashAmount + original.monthlyDepositTransferAmount)}`, {
            x: 450,
            y: height - 26 * fontSize,
            size: 11,
            font: timesRomanBold,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 26.35 * fontSize,
            },
            end: {
                x: 560,
                y: height - 26.35 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        const btmBannerUrl =
            "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Signoff_KL2_RPltnPzEh.jpg?updatedAt=1698062422962"
        const btmBannerImageBytes = await fetch(btmBannerUrl).then((res) =>
            res.arrayBuffer()
        );
        const btmBannerImage = await pdfDoc.embedJpg(btmBannerImageBytes);
        const btmBannerDims = btmBannerImage.scale(0.5);
        page.drawImage(btmBannerImage, {
            x: 0,
            y: height - 42.5 * fontSize,
            width: btmBannerDims.width,
            height: btmBannerDims.height,
        });

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(pdfBytes, `haofa-report-${original.outlet}`, "application/pdf");
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default createReport;
