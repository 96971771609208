import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import moment from "moment";
import "./reporting.css";
import Select from "react-select";
import createReport from "../../components/pdf/report";

export default function Report() {
    const salesReport = useSelector((state) => state.order.order);
    const currentUser = useSelector((state) => state.user.currentUser);


    const [inputs, setInputs] = useState({
        createdBy: currentUser._id,
        principle: undefined,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        outlet: undefined,
    });

    const getAdmin = async () => {
        const res =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? await publicRequest.get("/users/admin", {
                      params: { isAdmin: true },
                  })
                : await publicRequest.get("/users/superadmin", {
                      params: { isAdmin: true },
                  });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, principle: e.value, outlet: e.label };
        });
    };

    const handleDateFrom = (e) => {
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };
    const handleDateTo = (e) => {
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getAdmin();
        }
    }, [usersData]);


    return (
        <>
            <div className="productList">
                <div className="samerow">
                <div className="addProductItem mr-4">
                    <Select
                                    options={usersData.map((user, i) => {
                                        return {
                                            label: `${user.name}`,
                                            value: user._id,
                                        };
                                    })}
                                    onChange={handleChange}
                                    placeholder={"Outlet"}
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? process.env.REACT_APP_COUNTRY ===
                                              "SINGAPORE"
                                                ? getAdmin()
                                                : getAllAdmins()
                                            : {}
                                    }
                                />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateFrom"
                            value={inputs.dateFrom}
                            type="date"
                            onChange={(e) => handleDateFrom(e)}
                        />
                    </div>
                    <div className="addProductItem">
                        <input
                            className="inputFilter"
                            name="dateTo"
                            value={inputs.dateTo}
                            type="date"
                            onChange={(e) => handleDateTo(e)}
                        />
                    </div>
                </div>

                <div className="block">
                    <button
                        onClick={() => {
                            const reportData = {
                                ...salesReport[0],
                                dateTo: inputs.dateTo,
                                dateFrom: inputs.dateFrom,
                                outlet: inputs.outlet ? inputs.outlet : "All",
                              };
                            createReport(reportData)
                        }}
                        className="green buttonTop productListButton"
                    >
                        Generate Report
                    </button>
                </div>
            </div>
        </>
    );
}
